import styled from 'styled-components';
import Mooncourt from 'src/components/icons/Mooncourt';
import { Form } from 'src/components/common/form/Form';
import TextInput from 'src/components/common/form/TextInput';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/common/Button';
import Select, { ISelectItemProps } from 'src/components/common/form/Select';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';
import Checkbox from 'src/components/common/form/Checkbox';
import { Translations } from 'src/utils/Translations';
import Wysiwyg from 'src/components/common/Wysiwyg';
import { useContext, useEffect, useState } from 'react';
import DeleteIcon from 'src/components/icons/DeleteIcon';
import useContactApi from 'src/services/contact';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import useToast from 'src/hooks/useToast';
import { Notifications } from 'src/components/layout/app/Layout';
import LoadingSpinner from 'src/components/common/LoadingSpinner';

const Wrapper = styled.div`
  position: relative;
  align-self: center;
  margin-block: auto;
  display: grid;
  place-items: center;
  background: var(--color-black-900);
  border: 1px solid var(--color-text-default);
  border-radius: 1.25rem;
  padding: 3rem 1.5rem;
  width: 100%;
  max-width: 32rem;

  ${BREAKPOINT_MD} {
    padding: 3rem 4.375rem;
  }
`;

const Logo = styled(Mooncourt)`
  font-size: 1.5rem;
  margin-block-end: 0.5rem;
  color: var(--color-primary-750);
`;

const Headline = styled.h1`
  font-size: var(--font-size-headline-md);
  text-transform: uppercase;
  margin-block-end: 2rem;
`;

const SubHeadline = styled.p`
  font-size: var(--font-size-body-md);
  font-weight: 600;
`;

const Text = styled.p`
  font-size: var(--font-size-body-sm);
  line-height: 1.25;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block-end: 1rem;
`;

const TextArea = styled.textarea`
  height: 10rem;
  resize: none;
  border: 1px solid var(--color-text-default);
  border-radius: 0.625rem;
  padding: 0.75rem;
`;

const FileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block-end: 2.5rem;
`;

const FileInputButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const ClearFileInputButton = styled.button`
  cursor: pointer;
  font-size: 1.5rem;

  :hover {
    color: var(--color-primary-750);
  }
`;

const FileInput = styled.input`
  width: 100%;

  ::file-selector-button {
    border: 1px solid var(--color-text-default);
    border-radius: 0.625rem;
    background-color: var(--color-text-highlight);
    color: var(--color-text-default);
    height: 2.75rem;
    padding-inline: 1.25rem;
    font-weight: 600;

    :hover {
      color: var(--color-primary-750);
      border-color: var(--color-primary-750);
    }
  }
`;

const StyledWysiwyg = styled(Wysiwyg)`
  font-size: var(--font-size-body-sm);
  line-height: 1.25;
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-block-start: 1.25rem;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  margin: 1rem;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
`;

const Spinner = styled(LoadingSpinner)`
  font-size: 2rem;
`;

export interface IContactFormFieldValues {
  firstname: string;
  lastname?: string;
  email: string;
  subject: string;
  message: string;
  file: any;
  terms: boolean;
}

const ContactFormSubjects: ISelectItemProps[] = [
  { name: 'Support', value: 'support' },
  { name: 'Feedback', value: 'feedback' },
  { name: 'General Inquiry', value: 'general' },
];

export default function Contact() {
  const form = useForm<IContactFormFieldValues>();
  const fileInputFormProps = form.register('file');
  const textareaFormProps = form.register('message', { required: true });
  const accountState = useRecoilValue(AccountState);
  const file = form.watch('file');
  const { sendContactForm } = useContactApi();
  const { addToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  const [isLoading, setIsLoading] = useState(false);

  function clearFileInput() {
    form.setValue('file', []);
  }

  async function submitHandler(formData: IContactFormFieldValues) {
    setIsLoading(true);
    try {
      let data = new FormData();
      data.append('firstname', formData.firstname);
      formData.lastname && data.append('lastname', formData.lastname);
      data.append('email', accountState?.account?.email || formData.email);
      data.append('subject', formData.subject);
      data.append('message', formData.message);
      accountState?.id && data.append('account', accountState.id);
      formData.file?.length > 0 && data.append('file', formData.file[0]);
      const response = await sendContactForm(data);

      if (response?.status === 200) {
        form.reset();
        addToast({ headline: 'Success', text: 'Your contact request has been sent successfully.', type: 'success' });
      } else {
        notifications && addErrorToast(notifications.internalServerError);
      }
    } catch (error) {
      notifications && addErrorToast(notifications.internalServerError);
      console.log('[ERROR] sending contact form:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (file?.length && file[0].size > 5000000) {
      form.setError('file', { message: 'File is to big. Max 5MB' });
    } else {
      form.clearErrors('file');
    }
  }, [file, form]);

  return (
    <Wrapper>
      <Logo />

      <Headline>Contact</Headline>

      <Form form={form} onSubmit={submitHandler}>
        <InputWrapper>
          <TextInput name={'firstname'} placeholder={'First Name'} disabled={isLoading} />
          <TextInput name={'lastname'} placeholder={'Last Name (optional)'} optional disabled={isLoading} />
          <TextInput
            name={'email'}
            placeholder={accountState?.account?.email || 'Email'}
            disabled={isLoading || !!accountState?.account?.email}
          />
          <Select name={'subject'} items={ContactFormSubjects} placeholder={'Select a Subject'} disabled={isLoading} />
          <TextArea {...textareaFormProps} placeholder={'Message'} disabled={isLoading} />
        </InputWrapper>

        <FileInputWrapper>
          <SubHeadline>{Translations.contact.attachments.headline}</SubHeadline>
          <FileInputButtonWrapper>
            <FileInput {...fileInputFormProps} type={'file'} accept={'image/jpeg, image/png, image/jpg, image/heic'} disabled={isLoading} />
            {!!file?.length && (
              <ClearFileInputButton onClick={clearFileInput} disabled={isLoading}>
                <DeleteIcon />
              </ClearFileInputButton>
            )}
          </FileInputButtonWrapper>
          {form?.formState?.errors?.file && <Text>{form?.formState?.errors?.file?.message as string}</Text>}
          <Text>{Translations.contact.attachments.additionalInfo}</Text>
        </FileInputWrapper>

        <Checkbox name={'terms'} disabled={isLoading}>
          <StyledWysiwyg content={Translations.contact.termsCheckboxLabel} />
        </Checkbox>

        <StyledButton disabled={!form.formState.isValid || isLoading} type={'submit'}>
          {Translations.contact.submitButtonLabel}
        </StyledButton>
      </Form>
      {isLoading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
    </Wrapper>
  );
}
