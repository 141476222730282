// General
import { useEffect } from 'react';

// Helpers
import { injectContentsquareScript } from '@contentsquare/tag-sdk';

export const HotJar = () => {
  useEffect(() => {
    injectContentsquareScript({
      siteId: process.env.REACT_APP_HOTJAR_ID!,
      async: true, // Optional: Set to false to wait for script execution until after document parsing.
      defer: false // Optional: Set to true to defer script execution after document parsing.
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
