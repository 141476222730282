// General
import styled from 'styled-components';

// Hooks
import { useFieldError, useFieldProps, useFieldValue } from './Field';

// Types
import { InputHTMLAttributes } from 'react';

// Icons
import ChevronIcon from '../../icons/ChevronIcon';

// Components
import { InputError, InputHint } from './TextInput';

const Wrapper = styled.div`
  position: relative;
  display: grid;
`;

const IconWrapper = styled.div<{ hasError: boolean }>`
  grid-area: 1 / 1;
  display: flex;
  place-self: flex-end;
  align-self: center;
  padding-inline: 0.5rem;
  font-size: 1.5rem;
  color: var(--color-text-default);

  path {
    stroke-width: 1.25;
  }
`;

const StyledSelect = styled.select<{ hasError: boolean; placeholderSelected: boolean }>`
  width: 100%;
  grid-area: 1 / 1;
  border-radius: 0.625rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(${({ hasError }) => (hasError ? '--color-signal-red' : '--color-text-default')});
  padding: 0.75rem;
  line-height: 1.25;
  color: var(${({ placeholderSelected }) => (placeholderSelected ? '--color-grey-650' : '--color-text-default')});
  overflow: hidden;
  background: var(--color-black-900);

  &:disabled {
    pointer-events: none;
    color: var(--color-grey-650);
    border-color: var(--color-grey-650);
    background: var(--color-grey-900);

    + ${IconWrapper} {
      color: var(--color-grey-650);
    }
  }

  option {
    color: var(--color-text-highlight);
  }

  @media (hover: hover) {
    &:not(:disabled) {
      &:hover {
        color: var(--color-primary-750);
        border-color: var(--color-primary-750);

        + ${IconWrapper} {
          color: var(--color-primary-750);
        }
      }
    }
  }
`;

export interface ISelectItemProps {
  value: string;
  name: string;
  disabled?: boolean;
}

interface ITextInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  optional?: boolean;
  items: ISelectItemProps[];
  hint?: string;
}

export default function Select(props: ITextInputProps) {
  const { name, optional = false, defaultValue, hint, items, placeholder, className, ...selectProps } = props;

  const fieldProps = useFieldProps(name, optional, defaultValue?.toString());
  const error = useFieldError(name);
  const currentValue = useFieldValue(name);

  return (
    <Wrapper className={className}>
      <StyledSelect
        {...fieldProps}
        {...selectProps}
        defaultValue={placeholder ? '' : defaultValue}
        hasError={!!error?.message}
        placeholderSelected={defaultValue ? false : !currentValue}>
        {placeholder && (
          <option value={''} disabled>
            {placeholder}
          </option>
        )}

        {items.map(({ name, value, disabled }, index) => (
          <option key={index} value={value} disabled={disabled}>
            {name}
          </option>
        ))}
      </StyledSelect>

      <IconWrapper hasError={!!error?.message}>
        <ChevronIcon />
      </IconWrapper>

      {hint && <InputHint>{hint}</InputHint>}

      {error?.message && <InputError>{error.message}</InputError>}
    </Wrapper>
  );
}
