import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import CloseIcon from '../icons/CloseIcon';
import { BREAKPOINT_SM } from '../../styles/Breakpoints';

const Wrapper = styled.div<{ open: boolean }>`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color-primary-750);
  display: grid;
  padding-block: 1rem;
  padding-inline: 1rem;
  transform: translateY(${({ open }) => (open ? '0' : 'calc(-100% - 1rem)')});
  transition: transform 0.5s ease-in-out;
  border-block-end: 1px solid var(--color-text-default);

  ${BREAKPOINT_SM} {
    grid-template-columns: 1rem 1fr 1rem;
    column-gap: 1rem;
    padding-inline: 3rem;
    padding-block: 1.5rem;
  }
`;

const CloseButton = styled.button`
  margin-block-end: 0.25rem;
  place-self: flex-end;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));

  ${BREAKPOINT_SM} {
    grid-area: 1 / 3;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  ${BREAKPOINT_SM} {
    grid-area: 1 / 2;
  }
`;

interface IStickyActionBarProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
}

export default function StickyActionBar(props: IStickyActionBarProps) {
  const { open, onClose, children } = props;

  return (
    <Wrapper open={open}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      <ChildWrapper>{children}</ChildWrapper>
    </Wrapper>
  );
}
