import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useApi, useIsApiReady } from 'src/services/ApiProvider';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import LoadingAnimation from 'src/components/common/LoadingAnimation';
import useToast from 'src/hooks/useToast';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import { Button } from 'src/components/common/Button';
import LoginCTA from 'src/components/content/dashboard/LoginCTA';
import LoginModal from 'src/components/content/user/LoginModal';
import { Translations } from 'src/utils/Translations';

const LoadingWrapper = styled.div`
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-self: center;
`;

const StatusWrapper = styled.div`
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-self: center;
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
    svg {
        width: 2rem;
        height: 2rem;
    }
`;

const Headline = styled.h3`
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
`;

const ResultText = styled.p`
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: var(--color-text-default);
`;

const ResultHeadline = styled(Headline)`
    color: var(--color-signal-green);
`;

const Wrapper = styled.div`
    padding: 2rem;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-block-start: 2.5rem;
    height: 60vh;
`;
export default function Redeem() {
  const params = useParams();
  const { code } = params;
  const apiInstance = useApi();
  const isApiReady = useIsApiReady();
  const accountState = useRecoilValue(AccountState);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<any>(null);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);

  async function redeemCode(code: string) {
    try {
      const res = (await apiInstance.post(`/v2/account/subscriptions/redeem`, { code }))?.data;
      console.log(result);
      setIsLoading(false);
      setResult(res);
    } catch (error) {
      console.log(error);
      toast.addErrorToast({
        headline: 'Activation failed',
        text: 'Unfortunately the season pass could not be activated, please contact support.',
      });
    }
  }

  useEffect(() => {
    if (code && isApiReady) {
      redeemCode(code);
    }
  }, [code, isApiReady]);

  return (<><Wrapper>
    {
      (!accountState) && <LoginCTA showBenefits={false} setOpenLoginModal={setOpenLoginModal} headline={Translations.sso.headlineSeasonPass}/>
    }
    {
      (accountState && isLoading) && <LoadingWrapper>
        <StyledLoadingAnimation />
        <Headline>Activating Season Pass</Headline>
      </LoadingWrapper>
    }
    {
      result && <StatusWrapper>
        <ResultHeadline>Activation successful</ResultHeadline>
        <ResultText>YOUR MOONCOURT PRO MEMBERSHIP IS VALID UNTIL {new Date(result?.subscribedUtil).toLocaleString('de-DE', {timeStyle: 'short', dateStyle: 'medium'})}</ResultText>
      </StatusWrapper>
    }
  </Wrapper>
    {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
  </>);
}
