import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { AccountState } from '../../../states/AppData';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserApi } from '../../../services/user';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import GoogleIcon from '../../icons/GoogleIcon';
import AppleIcon from '../../icons/AppleIcon';
import EmailIcon from '../../icons/EmailIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import LoginModal from '../user/LoginModal';
import { useAccountApi } from '../../../services/account';
import { Glass } from '../../../styles/Custom';
import LinkHandler from 'src/components/common/LinkHandler';
import UserImage from 'src/components/common/UserImage';
import OnBoardingModal from 'src/components/content/user/OnBoardingModal';
import Wysiwyg from 'src/components/common/Wysiwyg';

const Wrapper = styled.div`
  display: flex;

  ${BREAKPOINT_MD} {
    align-items: flex-start;
  }
`;

const UserCard = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  gap: 2.5rem;
  width: 100%;

  ${BREAKPOINT_MD} {
    padding: 2rem;
    gap: 2rem;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`;

const UserProfileWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;
`;

const SectionHeadline = styled.h4`
  font-size: 1.25rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
`;

const EditSectionButton = styled.button`
  font-size: var(--font-size-body-md);
  line-height: 1;
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-750);
  }
`;

const SectionBody = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.25;
  font-size: var(--font-size-body-md);
  min-height: calc(1.25 * var(--font-size-body-md));

  svg {
    font-size: 0.875rem;
    flex-shrink: 0;
  }
`;

const StyledWysiwyg = styled(Wysiwyg)`
  gap: 0.325rem;
`;

export default function AccountTab() {
  const accountState = useRecoilValue(AccountState);

  const userApi = useUserApi();
  const accountApi = useAccountApi();

  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);

  const [onBoardingModalStep, setOnBoardingModalStep] = useState<undefined | 'username' | 'newsletter'>(undefined);

  useEffect(() => {
    userApi.refreshAccountState();
  }, []);

  const accountIcon = useMemo(() => {
    const accountType = accountState?.account.accountType;
    switch (accountType) {
      case 'GOOGLE':
        return <GoogleIcon />;
      case 'APPLE':
        return <AppleIcon />;
      case 'MAIL':
        return <EmailIcon />;
      case 'DISCORD':
        return <DiscordIcon />;
      default:
        return <EmailIcon />;
    }
  }, [accountState]);

  const handleChangePassword = useCallback(() => {
    if (accountState) {
      try {
        accountApi.resetPassword(accountState.account.email);
        setOpenLoginModal(true);
      } catch (error) {
        console.log('error resetting password:', error);
      }
    }
  }, [accountState]);

  const proUntil = useMemo(() => {
    if (accountState?.subscription?.subscribedUtil) {
      const date = new Date(accountState?.subscription?.subscribedUtil);
      return `${date.toLocaleString('de-DE', { dateStyle: 'medium', timeStyle: 'short' })}`;
    }

    return undefined;
  }, [accountState?.subscription?.subscribedUtil]);

  const accountStatus = useMemo(() => {
    if (accountState?.account?.level?.includes('legacy') && accountState?.account?.level?.includes('subscription')) {
      return `<p>Pro (Active until ${proUntil})</p> <p> Legacy (Livelong Membership)</p>`;
    } else if (accountState?.account?.level?.includes('legacy')) {
      return 'Legacy (Livelong Membership)';
    } else if (accountState?.account?.level?.includes('subscription')) {
      return `Pro (Active until ${proUntil})`;
    } else {
      return 'Lite';
    }
  }, [accountState, proUntil]);

  return (
    <Wrapper>
      <UserCard>
        <UserProfileWrapper>
          <UserImage
            size={52}
            username={accountState?.account.username}
            image={accountState?.account.image?.sizes.thumbnail.url}
            onClick={() => setOnBoardingModalStep('username')}
          />

          <Section>
            <SectionHead>
              <SectionHeadline>User Name</SectionHeadline>
              <EditSectionButton onClick={() => setOnBoardingModalStep('username')}>Edit</EditSectionButton>
            </SectionHead>
            <SectionBody>{accountState?.account.username}</SectionBody>
          </Section>
        </UserProfileWrapper>

        <Section>
          <SectionHead>
            <SectionHeadline>E-Mail Address</SectionHeadline>
            {accountState?.account.accountType === 'MAIL' && (
              <EditSectionButton onClick={handleChangePassword}>Change Password</EditSectionButton>
            )}
          </SectionHead>
          <SectionBody>
            {accountIcon}
            {accountState?.account.email}
          </SectionBody>
        </Section>

        <Section>
          <SectionHead>
            <SectionHeadline>Newsletter</SectionHeadline>
            {accountState?.newsletter?.externalId ? (
              <LinkHandler to={'/newsletter/optout'}>
                <EditSectionButton>Unsubscribe</EditSectionButton>
              </LinkHandler>
            ) : (
              <EditSectionButton onClick={() => setOnBoardingModalStep('newsletter')}>Subscribe</EditSectionButton>
            )}
          </SectionHead>
        </Section>

        <Section>
          <SectionHead>
            <SectionHeadline>Membership</SectionHeadline>
          </SectionHead>
          <SectionBody>
            <StyledWysiwyg content={accountStatus} />
          </SectionBody>
        </Section>
      </UserCard>

      {openLoginModal && <LoginModal initialModalType={'reset-otp'} onClose={() => setOpenLoginModal(false)} />}
      {onBoardingModalStep && <OnBoardingModal onClose={() => setOnBoardingModalStep(undefined)} initialStep={onBoardingModalStep} />}
    </Wrapper>
  );
}
