import styled from 'styled-components';
import PencilIcon from '../icons/PencilIcon';
import PlusIcon from 'src/components/icons/PlusIcon';
import AnimatedAlienIcon from 'src/components/icons/AnimatedAlienIcon';

const StyledImage = styled.img`
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

const Username = styled.h6`
  grid-area: 1 / 1;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: var(--color-text-default);
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

const StyledPencilIcon = styled(PencilIcon)`
  grid-area: 1 / 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: var(--color-primary-750);
`;

const StyledAlienIcon = styled(AnimatedAlienIcon)`
  grid-area: 1 / 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

const AddIconWrapper = styled.div`
  z-index: 1;
  border: 1px solid var(--color-text-default);
  display: grid;
  place-items: center;
  grid-area: 1 / 1;
  align-self: flex-end;
  justify-self: flex-end;
  border-radius: 50%;
  background-color: var(--color-grey-900);
  font-size: 1rem;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
`;

const Wrapper = styled.div<{ hasHover: boolean }>`
  grid-area: 1 / 1;
  display: grid;
  place-items: center;
  overflow: hidden;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 1px solid var(--color-text-default);
  background-color: var(--color-grey-900);
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
`;

const OuterWrapper = styled.div`
  display: grid;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      ${Wrapper} {
        background-color: var(--color-black-900);
        border-color: var(--color-primary-750);
      }

      ${StyledPencilIcon} {
        opacity: 1;
      }

      ${AddIconWrapper} {
        background-color: var(--color-black-900);
        border-color: var(--color-primary-750);
        color: var(--color-primary-750);
      }

      ${StyledImage}, ${Username}, ${StyledAlienIcon} {
        opacity: 0;
      }
    }
  }
`;

interface IUserImageProps {
  className?: string;
  onClick?: () => void;
  username?: string;
  image?: string;
  size?: number;
  hasEditIcon?: boolean;
}

export default function UserImage(props: IUserImageProps) {
  const { className, onClick, username, image, size = 52, hasEditIcon = false } = props;

  return (
    <OuterWrapper className={className}>
      <Wrapper
        as={onClick ? 'button' : 'div'}
        onClick={onClick}
        type={onClick ? 'button' : undefined}
        style={{ width: size, height: size }}
        hasHover={Boolean(onClick) || Boolean(username)}>
        <StyledPencilIcon style={{ fontSize: size / 3 }} />
        {image ? (
          <StyledImage src={image} alt={'User Profile Image'} />
        ) : username ? (
          <Username style={{ fontSize: size / 2.5, marginBlockStart: size * 0.05 }}>{username.slice(0, 2)}</Username>
        ) : (
          <StyledAlienIcon eyeColor={'var(--color-signal-green)'} style={{ fontSize: size / 2 }} />
        )}
      </Wrapper>
      {hasEditIcon && (
        <AddIconWrapper style={{ width: size / 3.5, height: size / 3.5 }}>{image ? <PencilIcon /> : <PlusIcon />}</AddIconWrapper>
      )}
    </OuterWrapper>
  );
}
