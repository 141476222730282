// Helpers
import { GoogleAnalytics } from './GoogleAnalytics';

// States
import { useAllowedCookies } from '../../states/CookieState';
import React from 'react';
import { HotJar } from 'src/components/layout/HotJar';

export const ExternalScripts = () => {
  const allowedCookies = useAllowedCookies();

  return <>
    {
      allowedCookies.some((key) => ['performance', 'comfort'].includes(key)) &&
      <>
        <GoogleAnalytics />
        <HotJar />
      </>
    }
  </>;
};
