import styled from 'styled-components';
import { ITrainingStatus } from '../../../../types/stake';
import { Button } from '../../../common/Button';
import { useCountdown } from '../../../../hooks/useCountdown';
import { useMemo } from 'react';
import { BREAKPOINT_LG } from '../../../../styles/Breakpoints';
import { FontFamilies } from '../../../../styles/FontFamilies';
import PointsIcon from '../../../icons/PointsIcon';
import DumbbellIcon from '../../../icons/DumbbellIcon';
import { Glass } from '../../../../styles/Custom';

export const TrainingsBoxWrapper = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.75rem;
  container-type: inline-size;
`;

export const TrainingsBoxHeadline = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-end: 0.75rem;

  ${BREAKPOINT_LG} {
    margin-block-end: 1rem;
  }
`;

export const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.875rem;
  border: 1px solid var(--color-grey-650);
  margin: -1px;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  box-shadow: 0 0 22px 0 #000;
  margin-block-end: 1.75rem;
`;

export const CountdownHead = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  background: var(--color-grey-650);
  font-family: ${FontFamilies.headline};
  color: var(--color-text-highlight);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 2px;
`;

export const Countdown = styled.p`
  width: 5.25rem;
`;

export const CountdownBody = styled.h5`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 0.75rem;
  justify-content: center;
  font-size: 3.25rem;
  line-height: 1;
  font-weight: 700;
  height: 6.25rem;

  svg {
    font-size: 2rem;
    margin-block-end: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;

  svg {
    font-size: 1.5rem;
  }
`;

interface ITrainingBoxProps {
  trainingStatus?: ITrainingStatus;
  claim: () => void;
  stake: () => void;
}

export default function TrainingBox(props: ITrainingBoxProps) {
  const { trainingStatus, claim, stake } = props;

  const countdownDate = useMemo(() => {
    return new Date(new Date().getTime() + (trainingStatus?.nextPointsIn || 0)).toString();
  }, [trainingStatus]);

  const [days, hours, minutes, seconds] = useCountdown(countdownDate);

  const tokensToStake = useMemo(() => trainingStatus?.tokens.find((item) => !item.staked), [trainingStatus]);

  return (
    <TrainingsBoxWrapper>
      <TrainingsBoxHeadline>claimable moonpoints</TrainingsBoxHeadline>

      <CountdownWrapper>
        <CountdownHead>
          <p>next mp in</p>
          <Countdown>{seconds === '-1' ? '00:00:00:00' : `${days}:${hours}:${minutes}:${seconds}`}</Countdown>
        </CountdownHead>

        <CountdownBody>
          <PointsIcon /> {trainingStatus?.claimablePoints || 0}
        </CountdownBody>
      </CountdownWrapper>

      <ButtonWrapper>
        <StyledButton onClick={claim} theme={'secondary'} disabled={(trainingStatus?.claimablePoints || 0) <= 0}>
          <PointsIcon /> Rest & Claim MP
        </StyledButton>

        <StyledButton onClick={stake} theme={'primary'} disabled={!tokensToStake}>
          <DumbbellIcon /> Start Training
        </StyledButton>
      </ButtonWrapper>
    </TrainingsBoxWrapper>
  );
}
