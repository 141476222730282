import styled from 'styled-components';
import MembershipCard from './subcomponents/MembershipCard';
import { BREAKPOINT_LG } from 'src/styles/Breakpoints';
import { useMemo, useState } from 'react';
import LoginModal from 'src/components/content/user/LoginModal';
import { useIsApiReady } from 'src/services/ApiProvider';
import TrustPilotBox from 'src/components/content/membership/subcomponents/TrustPilotBox';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-block-start: 2.5rem;
  width: 100%;
`;

const Headline = styled.h2`
  text-align: center;
  font-size: var(--font-size-headline-lg);
  font-weight: 600;
  text-transform: uppercase;
  margin-block-end: 1rem;

  ${BREAKPOINT_LG} {
    margin-block-end: 1.75rem;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: var(--font-size-body-lg);
  max-width: 800px;
  align-self: center;
  margin-block-end: 2.75rem;

  ${BREAKPOINT_LG} {
    margin-block-end: 3.75rem;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
  gap: 2.75rem 2rem;
  justify-content: center;
`;

export type IMembershipType = 'free' | 'subscription' | 'legacy';

export default function Membership() {
  const isApiReady = useIsApiReady();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [membershipPaymentInterval, setMembershipPaymentInterval] = useState<'30days' | '90days'>('30days');

  const subscriptionLink = useMemo(() => {
    if (membershipPaymentInterval === '30days') {
      return {
        href: process.env.REACT_APP_SUBSCRIPTION_LINK_30_DAYS || '',
        title: 'Subscription',
        target: '_blank',
      };
    } else {
      return {
        href: process.env.REACT_APP_SUBSCRIPTION_LINK_90_DAYS || '',
        title: 'Subscribe',
        target: '_blank',
      };
    }
  }, [membershipPaymentInterval]);

  return (
    <Wrapper>
      <Headline>Start your mooncourt journey</Headline>

      <Description>
        Choose your path to the moon: Start for free or unlock exclusive benefits with a membership. Join Mooncourt and take your sneaker
        passion to new heights.
      </Description>

      <TrustPilotBox />

      <ItemWrapper>
        <MembershipCard type={'free'} onClick={() => setOpenLoginModal(true)} disabled={isApiReady} />

        <MembershipCard
          type={'subscription'}
          link={subscriptionLink}
          membershipPaymentInterval={membershipPaymentInterval}
          setMembershipPaymentInterval={setMembershipPaymentInterval}
        />

        <MembershipCard
          type={'legacy'}
          link={{ href: 'https://warehouse.mooncourt.xyz/products/mvp-pass', title: 'MVP Pass', target: '_blank' }}
        />
      </ItemWrapper>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </Wrapper>
  );
}
