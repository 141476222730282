import styled from 'styled-components';
import { Translations } from 'src/utils/Translations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  margin-block-end: 1.25rem;
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  cursor: pointer;
`;

const IntervalLabel = styled.p`
  font-size: var(--font-size-body-md);
  font-weight: 600;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.25rem 1.25rem;
  border-radius: 1.5rem;
  gap: 0.25rem;
  background-color: var(--color-primary-750);
  padding: 0.25rem;
  height: 1.75rem;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const PaymentHint = styled.p`
  color: var(--color-grey-650);
  font-size: var(--font-size-body-sm);
`;

const Indicator = styled.span<{ position: 'left' | 'right' }>`
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--color-text-default);
  border-radius: 100%;
  transform: translateX(${({ position }) => (position === 'left' ? '0' : 'calc(1.25rem + 0.25rem)')});
  transition: transform 0.25s ease-in-out;
`;

interface IMembershipPaymentIntervalSwitchProps {
  interval: '30days' | '90days';
  setInterval: (value: '30days' | '90days') => void;
}

export default function MembershipPaymentIntervalSwitch(props: IMembershipPaymentIntervalSwitchProps) {
  const { interval, setInterval } = props;

  return (
    <Wrapper>
      <ToggleWrapper onClick={() => setInterval(interval === '30days' ? '90days' : '30days')}>
        <IntervalLabel>{Translations.membership.types.subscription.payment[0].title}</IntervalLabel>
        <InputWrapper>
          <Indicator position={interval === '30days' ? 'left' : 'right'} />
          <Input type={'checkbox'} onClick={() => setInterval(interval === '30days' ? '90days' : '30days')} />
        </InputWrapper>
        <IntervalLabel>{Translations.membership.types.subscription.payment[1].title}</IntervalLabel>
      </ToggleWrapper>
      <PaymentHint>{Translations.membership.types.subscription.paymentHint}</PaymentHint>
    </Wrapper>
  );
}
