import { useApi } from 'src/services/ApiProvider';

export default function useContactApi() {
  const apiInstance = useApi();

  const sendContactForm = async (data: any) => {
    return await apiInstance.post(`/v2/account/contact`, data);
  };

  return {
    sendContactForm,
  };
}
