import styled from 'styled-components';
import LinkHandler from 'src/components/common/LinkHandler';
import { CardBody, CardContainer, CardImageWrapper, CardWrapper, InnerCardWrapper } from 'src/components/content/cards/CardStyles';
import PointsIcon from 'src/components/icons/PointsIcon';

const StyledCardContainer = styled(CardContainer)`
  max-width: 300px;
`;

const StyledCardWrapper = styled(CardWrapper)`
  box-shadow: var(--box-shadow-rarity-common);
`;

const StyledCardBody = styled(CardBody)`
  align-self: stretch;
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25cqw;
  margin-block-start: auto;
`;

const ExpiryDate = styled.p`
  text-align: center;
  font-family: var(--font-family-headline);
  font-size: 7.5cqw;
  font-weight: 600;
  letter-spacing: 1.2;
`;

const MoonpointsLabel = styled.p`
  svg {
    font-size: 0.625rem;
  }

  font-family: var(--font-family-headline);
  font-size: 11.25cqw;
  font-weight: 600;
`;

interface ISeasonPassCardProps {
  date: string;
  points: number;
}

export default function SeasonPassCard(props: ISeasonPassCardProps) {
  const { date, points } = props;

  return (
    <StyledCardContainer>
      <StyledCardWrapper as={LinkHandler} to={`/profile`}>
        <InnerCardWrapper>
          <CardImageWrapper itemAvailable={true}>
            <img src={'/images/season_pass.jpg'} alt={'Season Pass Background'} />
          </CardImageWrapper>

          <StyledCardBody>
            <ExpiryDate>EXPIRES {new Date(date).toLocaleDateString()}</ExpiryDate>
            <PointsWrapper>
              <PointsIcon />
              <MoonpointsLabel>{points}</MoonpointsLabel>
            </PointsWrapper>
          </StyledCardBody>
        </InnerCardWrapper>
      </StyledCardWrapper>
    </StyledCardContainer>
  );
}
